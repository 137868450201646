<template>
  <div>
    <button-top
      v-if="permissions.c"
      tooltip="Crear usuario"
      name="Usuario"
      @action="$router.push('/usuarios/create')"
    ></button-top>
    <div class="row justify-content-start mt-1">
      <div class="col-4">
        <b-form-group>
          <label for="search" class="text-success"
            >Buscar (Id. usaurio, Nombre, Cuenta)</label
          >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search"
              v-model="search"
              class="form-control-merge"
              name="search"
              @input="filter"
              placeholder=""
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <b-table :items="items" :fields="fields" :busy="load">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(actions)="row">
        <span
          v-if="permissions.u"
          class="ml-1 cursor-pointer"
          v-b-tooltip.hover.top="'Ver usuario'"
          @click="$router.push(`/usuarios/show/${row.item.id}`)"
        >
          <feather-icon icon="MoreVerticalIcon" size="18" />
        </span>
      </template>
    </b-table>
    <b-row class="mt-1">
      <b-col>
        <b-pagination
          v-model="current_page"
          :total-rows="total"
          :per-page="per_page"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
      <b-col>
        <b-form-select
          class="fl"
          v-model="per_page"
          :options="options_per_page"
          title="Registros por página"
        ></b-form-select>
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
  </div>
</template>
  
<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      load: true,
      search: "",
      fields: [
        {
          key: "id",
          label: "Id. usuario",
          thStyle: { width: "19%" },
        },
        {
          key: "email",
          label: "Cuenta",
          thStyle: { width: "19%" },
        },

        {
          key: "mobile",
          label: "Movil",
          thStyle: { width: "19%" },
        },

        {
          key: "third_name",
          label: "Tercero",
          thStyle: { width: "19%" },
          formatter: (value) => {
            return value == null ? "n/a" : value;
          },
        },

        {
          key: "state",
          label: "Estado",
          thStyle: { width: "19%" },
          formatter: (value) => {
            return value == 1 ? "Activo" : "Inactivo";
          },
        },

        {
          key: "actions",
          label: "",
          thStyle: { width: "5%" },
        },
      ],
      items: [],
      current_page: 1,
      per_page: 5,
      total: 0,
      typing_timer: null,
      options_per_page: [5, 10, 15, 25, 50, 100],
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    async current_page(new_value, old_value) {
      await this.get();
    },
    async per_page(new_value, old_value) {
      await this.get();
    },
  },

  methods: {
    async init() {
      await this.getPermission();
      await this.get();
    },

    async filter() {
      clearTimeout(this.typing_timer);
      this.typing_timer = setTimeout(async () => {
        this.current_page = 1;
        await this.get();
      }, 1000);
    },

    async get() {
      this.load = true;
      this.items = [];
      this.$http
        .get("administration/user/index", {
          params: {
            current_page: this.current_page,
            per_page: this.per_page,
            filter: this.search,
          },
        })
        .then((res) => {
          this.items = res.data.data;
          this.total = res.data.total;
        })
        .catch((error) => {
          this.notify("Error", error.response.data.message, "danger");
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>
 
  